import React, { useState } from "react";
// import Subscribe from "../../components/subscribe";
import SwapContent from "../../components/Swap";
import Layout from "layouts";
import { Helmet } from 'react-helmet'

export default function Swap() {
    // const [showSubscribe, setShowSubscribe] = useState(false);
    return (
        <Layout isSwap={true}>
            <Helmet title={'MASQ | SWAP'} defer={false} />
            <SwapContent />
            {/* <Subscribe
                show={showSubscribe}
                toggleSubscribeOverlay={setShowSubscribe}
            /> */}
        </Layout>
    );
}
